<template>
  <div class="scrollable" v-click-outside="hidePopup">
    <!-- 聊天界面 -->
    <div class="chat-container animated bounceInUp" v-show="isShow" @click="closeAll"
      @contextmenu.prevent.stop="closeAll">
      <!-- 标题 -->
      <ul>
        <li><a href="https://formoji.fun">本站不再维护，请访问新版</a></li>
        <li @click="currentTab = '1'" :class="{ active: currentTab === '1' }">群聊</li>
        <li @click="currentTab = '2'" :class="{ active: currentTab === '2' }">AI</li>
        <li @click="hidePopup" class="iconfont icon-close" style="font-size: 1.1rem;" title="关闭"></li>
      </ul>
      <div class="chat-content">
        <span class="tips" v-if="currentTab === '1'">当前{{ count }}人在线</span>
        <div v-if="currentTab === '1'" class="chat-message" id="message">
          <div :class="isMyMessage(item)" v-for="(item, index) of chatRecordList" :key="index">
            <!-- 头像 -->
            <img :src="item.avatar" :class="isleft(item)" />
            <div>
              <div class="chat-nickname" v-if="!isSelf(item)">
                {{ item.nickname }}
                <span style="margin-left:12px">{{ item.createTime | hour }}</span>
              </div>
              <!-- 内容 -->
              <div ref="content" @contextmenu.prevent.stop="showBack(item, index, $event)" :class="isMyContent(item)">
                <!-- 文字消息 -->
                <div v-if="item.type == 3" v-html="markedContent(item)" />
                <div class="back-menu" ref="backBtn" @click="back(item, index)">
                  撤回
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 只展示单独的消息，数据存放localstorage -->
        <div v-else class="chat-message" id="message">
          <div :class="isMyMessage(item)" v-for="(item, index) of aiRecordList" :key="index">
            <!-- 头像 -->
            <img :src="item.avatar" :class="isleft(item)" />
            <div>
              <div class="chat-nickname" v-if="!isSelf(item)">
                {{ item.nickname }}
                <span style="margin-left:12px">{{ item.createTime | hour }}</span>
              </div>
              <!-- 内容 -->
              <div ref="content" @contextmenu.prevent.stop="showBack(item, index, $event)" :class="isMyContent(item)">
                <!-- 文字消息 -->
                <div v-if="item.type == 3" v-html="item.content" />
                <!-- 语音消息 -->
                <div v-if="item.type == 5" @click.prevent.stop="playVoice(item)">
                  <audio @ended="endVoice(item)" @canplay="getVoiceTime(item)" ref="voices" :src="item.content"
                    style="display:none" />
                  <!-- 音频时长 -->
                </div>
                <div class="back-menu" ref="backBtn" @click="back(item, index)">
                  撤回
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- 输入框 -->
      <div class="footer">
        <!-- 表情框 -->
        <div class="emoji-box" v-show="isEmoji">
          <Emoji :chooseEmoji="true" @addEmoji="addEmoji" />
        </div>
        <div class="emoji-border" v-show="isEmoji" />
        <!-- 文字输入 -->
        <textarea v-show="!isVoice" ref="chatInput" v-model="content" @keydown.enter="saveMessage($event)"
          placeholder="请输入内容" />
        <!-- 表情 -->
        <i class="iconfont iconbiaoqing emoji" :style="isEmoji ? 'color:#FFC83D' : ''" @click.prevent.stop="openEmoji" />
        <!-- 发送按钮 -->
        <i :class="isInput" @click="saveMessage" style="font-size: 1.5rem" />
      </div>
      
    </div>
    <!-- 未读数量 -->
    <div class="chat-btn" @click="open">
      <span class="unread" v-if="unreadCount > 0">{{ unreadCount }}</span>
      <span class="mr-3 iconfont icon-weixin" style="color:green; font-size: 3rem" />
    </div>
  </div>
</template>

<script>
import Recorderx, { ENCODE_TYPE } from "recorderx";
import Emoji from "./Emoji";
import EmojiList from "../assets/js/emoji";
import MarkdownIt from 'markdown-it';
//copycode
import Clipboard from "clipboard";
const md = new MarkdownIt();


export default {
  components: {
    Emoji
  },
  updated() {
    var ele = document.getElementById("message");
    ele.scrollTop = ele.scrollHeight;
  },
  beforeDestroy() {
    clearInterval(this.heartBeat);
  },
  data: function () {
    return {
      currentTab: '1',
      isEmoji: false,
      isShow: false,
      websocket: null,
      content: "",
      chatRecordList: [],
      aiRecordList: [],
      voiceList: [],
      rc: null,
      ipAddress: "",
      ipSource: "",
      count: 0,
      unreadCount: 0,
      isVoice: false,
      voiceActive: false,
      startVoiceTime: null,
      WebsocketMessage: {
        type: null,
        data: null
      },
      heartBeat: null
    };
  },
  methods: {
    markedContent(item) {
      this.clipboard = new Clipboard(".copy-btn");
      this.clipboard.on("success", () => {
        this.$toast({ type: "success", message: "复制成功" });
      });
      //只对chatgpt的内容进行渲染
      if (item.userId == 0) {
        return md.render(item.content);
      }
      return item.content;
    },
    hidePopup() {
      if (this.isShow == true) {
        this.isShow = false;
      }
    },
    open() {
      if (this.websocket == null) {
        this.connect();
      }
      this.unreadCount = 0;
      this.isShow = !this.isShow;
      this.aiChatRecords();
    },
    openEmoji() {
      this.isEmoji = !this.isEmoji;
      this.isVoice = false;
    },
    connect() {
      var that = this;
      this.websocket = new WebSocket(this.blogInfo.websiteConfig.websocketUrl);
      // this.websocket = new WebSocket("ws://localhost:8080/websocket");
      // 连接发生错误的回调方法
      this.websocket.onerror = function (event) {
        console.log(event);
        alert("失败");
      };
      // 连接成功建立的回调方法
      this.websocket.onopen = function (event) {
        console.log(event);
        // 发送心跳消息
        that.heartBeat = setInterval(function () {
          var beatMessage = {
            type: 6,
            data: "ping"
          };
          that.websocket.send(JSON.stringify(beatMessage));
        }, 30 * 1000);
      };
      // 接收到消息的回调方法
      this.websocket.onmessage = function (event) {
        const data = JSON.parse(event.data);
        switch (data.type) {
          case 1:
            // 在线人数
            that.count = data.data;
            break;
          case 2:
            // 历史记录

            that.chatRecordList = data.data.chatRecordList;
            that.chatRecordList.forEach(item => {
              if (item.type == 5) {
                that.voiceList.push(item.id);
              }
            });
            that.ipAddress = data.data.ipAddress;
            that.ipSource = data.data.ipSource;
            break;
          case 3:
            // 文字消息
            if (data.data.tab == '2') {
              //当前用户的消息才回
              let loginInfo = localStorage.getItem("userinfo");
              
              if (loginInfo == undefined) {
                return;
              }
              if (data.data.toId !=undefined && JSON.parse(loginInfo).userInfoId != data.data.toId){
                return;
              }
              that.aiRecordList.push(data.data);
              localStorage.setItem("aiRecordList",JSON.stringify(that.aiRecordList));
              
            }else{
              that.chatRecordList.push(data.data);
            }
            if (!that.isShow) {
              that.unreadCount++;
            }
            break;
          case 4:
            // 撤回
            if (data.data.isVoice) {
              that.voiceList.splice(that.voiceList.indexOf(data.data.id), 1);
            }
            for (var i = 0; i < that.chatRecordList.length; i++) {
              if (that.chatRecordList[i].id == data.data.id) {
                that.chatRecordList.splice(i, 1);
                i--;
              }
            }
            break;
          case 5:
            // 语音消息
            that.voiceList.push(data.data.id);
            that.chatRecordList.push(data.data);
            if (!that.isShow) {
              that.unreadCount++;
            }
            break;
        }
      };
      //连接关闭的回调方法
      this.websocket.onclose = function () { };
    },
    saveMessage(e) {
      e.preventDefault();
      if (this.content.trim() == "") {
        this.$toast({ type: "error", message: "内容不能为空" });
        return false;
      }
      //未登录用户不能与chatgpt对话
      if (this.content.substring(0, 5) == "@chat" || this.currentTab == "2") {
        //判断用户是否登录
        if (this.userId == null) {
          this.$toast({ type: "error", message: "未登录用户不能与chat聊天" });
          return false;
        }
      }
      //解析表情
      var reg = /\[.+?\]/g;
      this.content = this.content.replace(reg, function (str) {
        return (
          "<img src= '" +
          EmojiList[str] +
          "' width='24'height='24' style='margin: 0 1px;vertical-align: text-bottom'/>"
        );
      });
      var socketMsg = {
        nickname: this.nickname,
        avatar: this.avatar,
        content: this.content,
        userId: this.userId,
        tab: this.currentTab,
        type: 3,
        ipAddress: this.ipAddress,
        ipSource: this.ipSource
      };
      this.WebsocketMessage.type = 3;
      this.WebsocketMessage.data = socketMsg;
      this.websocket.send(JSON.stringify(this.WebsocketMessage));
      this.content = "";
    },
    addEmoji(key) {
      this.isEmoji = false;
      this.$refs.chatInput.focus();
      this.content += key;
    },
    // 展示菜单
    showBack(item, index, e) {
      this.$refs.backBtn.forEach(item => {
        item.style.display = "none";
      });
      if (
        // 匿名用户不允许撤回
        // item.ipAddress == this.ipAddress ||
        (item.userId != null && item.userId == this.userId)
      ) {
        this.$refs.backBtn[index].style.left = e.offsetX + "px";
        this.$refs.backBtn[index].style.bottom = e.offsetY + "px";
        this.$refs.backBtn[index].style.display = "block";
      }
    },
    // 撤回消息
    back(item, index) {
      var socketMsg = {
        id: item.id,
        isVoice: item.type == 5
      };
      this.WebsocketMessage.type = 4;
      this.WebsocketMessage.data = socketMsg;
      this.websocket.send(JSON.stringify(this.WebsocketMessage));
      this.$refs.backBtn[index].style.display = "none";
    },
    closeAll() {
      this.isEmoji = false;
      if (this.chatRecordList.length > 0) {
        this.$refs.backBtn.forEach(item => {
          item.style.display = "none";
        });
      }
    },
    // 录音开始
    translationStart() {
      this.voiceActive = true;
      let that = this;
      that.rc = new Recorderx();
      that.$nextTick(() => {
        that.rc
          .start()
          .then(() => {
            that.startVoiceTime = new Date();
            console.log("start recording");
          })
          .catch(error => {
            console.log("Recording failed.", error);
          });
      });
    },
    // 录音结束
    translationEnd() {
      console.log("结束");
      this.voiceActive = false;
      this.rc.pause();
      if (new Date() - this.startVoiceTime < 1000) {
        this.$toast({ type: "error", message: "按键时间太短" });
        return false;
      }
      var wav = this.rc.getRecord({
        encodeTo: ENCODE_TYPE.WAV
      });
      var file = new File([wav], "voice.wav", {
        type: wav.type
      });
      var formData = new window.FormData();
      formData.append("file", file);
      formData.append("type", 5);
      formData.append("nickname", this.nickname);
      formData.append("avatar", this.avatar);
      if (this.userId != null) {
        formData.append("userId", this.userId);
      }
      formData.append("ipAddress", this.ipAddress);
      formData.append("ipSource", this.ipSource);
      var options = {
        url: "/api/voice",
        data: formData,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };
      this.axios(options);
    },
    translationmove() { },
    // 播放语音
    playVoice(item) {
      var player = this.$refs.voices[this.voiceList.indexOf(item.id)];
      if (player.paused) {
        player.play();
        this.$refs.plays[this.voiceList.indexOf(item.id)].$el.style.display =
          "none";
        this.$refs.pauses[this.voiceList.indexOf(item.id)].$el.style.display =
          "inline-flex";
      } else {
        this.$refs.plays[this.voiceList.indexOf(item.id)].$el.style.display =
          "inline-flex";
        this.$refs.pauses[this.voiceList.indexOf(item.id)].$el.style.display =
          "none";
        player.pause();
      }
    },
    // 语音结束
    endVoice(item) {
      this.$refs.plays[this.voiceList.indexOf(item.id)].$el.style.display =
        "inline-flex";
      this.$refs.pauses[this.voiceList.indexOf(item.id)].$el.style.display =
        "none";
    },
    // 获取语音时长
    getVoiceTime(item) {
      var time = this.$refs.voices[this.voiceList.indexOf(item.id)].duration;
      time = Math.ceil(time);
      var str = "⬝⬝⬝";
      for (var i = 0; i < time; i++) {
        if (i % 2 == 0) {
          str += "⬝";
        }
      }
      this.$refs.voiceTimes[this.voiceList.indexOf(item.id)].innerHTML =
        " " + str + " " + time + " ''";
    },
    aiChatRecords() {
      let aiRecords = localStorage.getItem("aiRecordList");
      if (aiRecords != undefined) {
        this.aiRecordList = JSON.parse(aiRecords);
      }
    }
  },
  computed: {
    chatBoxFlag: {
      set(value) {
        this.$store.state.chatBoxFlag = value;
      },
      get() {
        return this.$store.state.chatBoxFlag;
      }
    },
    isSelf() {
      return function (item) {
        return (this.currentTab == '2' && item.userId != 0) || (item.userId != null && item.userId == this.userId);
      };
    },
    isleft() {
      return function (item) {
        return this.isSelf(item)
          ? "chat-user-avatar chat-right-avatar"
          : "chat-user-avatar chat-left-avatar";
      };
    },
    isMyContent() {
      return function (item) {
        return this.isSelf(item) ? "chat-my-content" : "chat-user-content";
      };
    },
    isMyMessage() {
      return function (item) {
        return this.isSelf(item) ? "chat-my-message" : "chat-user-message";
      };
    },
    blogInfo() {
      return this.$store.state.blogInfo;
    },
    nickname() {
      return this.$store.state.nickname != null
        ? this.$store.state.nickname
        : this.ipAddress;
    },
    avatar() {
      return this.$store.state.avatar != null
        ? this.$store.state.avatar
        : this.$store.state.blogInfo.websiteConfig.touristAvatar;
    },
    userId() {
      return this.$store.state.userId;
    },
    isInput() {
      return this.content.trim() != ""
        ? "iconfont iconzhifeiji submit-btn"
        : "iconfont iconzhifeiji";
    }
  }
};
</script>

<style scoped>

.scrollable {
    height: 0px; /* 设置容器的高度 */
    overflow: scroll; /* 设置 overflow 属性 */
    z-index: 9999;
}
.chat-container ul {
  list-style: none;
  border-radius: 10px;
  margin: 0;
  padding: 0;
  
}
.chat-container .tips {
  margin: 0 auto;
  color: black;
  background-color:rgb(207, 207, 207);
  display:block;
}

.chat-container li {
  display: inline-block;
  margin-right: 0px;
  background-color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
}

.chat-container li.active {
  background-color: rgb(162, 209, 240);
}

.chat-container .tab-content {
  display: none;
  padding: 10px;
  background-color: #fff;
}

.chat-container .tab-content.active {
  display: block;
}


@media (min-width: 760px) {
  .chat-container {
    
    position: fixed;
    color: #4c4948 !important;
    bottom: 104px;
    right: 20px;
    height: calc(85% - 64px - 20px);
    max-height: 590px !important;
    min-height: 250px !important;
    width: 400px !important;
    border-radius: 16px !important;
  }

  .close {
    display: none;
  }
}

@media (max-width: 760px) {
  .chat-container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .close {
    display: block;
    margin-left: auto;
  }
}

.chat-container {
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.16) !important;
  font-size: 14px;
  background: #f4f6fb;
  z-index: 1200;
}

.chat-btn {
  border-radius: 100px !important;
  position: fixed;
  bottom: 15px;
  right: 5px;
  cursor: pointer;
  height: 60px !important;
  width: 60px !important;
  z-index: 1000 !important;
  user-select: none;
}

.header {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  background: #ffffff;
  border-radius: 1rem 2rem 0 0;
  box-shadow: 0 2px 5px -6px rgba(50, 50, 93, 0.08),
    0 4px 6px -8px rgba(50, 50, 93, 0.04);
}

.footer {
  padding: 8px 16px;
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #f7f7f7;
  border-radius: 0 0 1rem 1rem;
  display: flex;
  align-items: center;
}

.footer textarea {
  background: #fff;
  padding-left: 10px;
  padding-top: 8px;
  width: 100%;
  height: 32px;
  outline: none;
  resize: none;
  overflow: hidden;
  font-size: 13px;
}

.voice-btn {
  font-size: 13px;
  outline: none;
  height: 32px;
  width: 100%;
  background: #fff;
  border-radius: 2px;
}


.chat-message {
  position: absolute;
  width: 100%;
  padding: 1px 10px 0 1px;

  top: 55px;
  bottom: 60px;
  overflow-y: auto;
  box-shadow: 0px 0px 5px 2px rgba(180, 223, 240, 0.4);
  overflow-x: hidden;
}


.chat-user-message {
  display: flex;
  margin-bottom: 10px;
}

.chat-my-message {
  display: flex;
  margin-bottom: 10px;
  justify-content: flex-end;
}

.chat-left-avatar {
  margin-right: 10px;
}

.chat-right-avatar {
  order: 1;
  margin-left: 10px;
}

.chat-user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.chat-text {
  color: #999;
  text-align: center;
  font-size: 12px;
  margin-bottom: 12px;
}
.chat-nickname {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 5px;
}

.chat-user-content {
  position: relative;
  color: black;
  background-color: #fff;
  padding: 10px;
  margin-right: 25px;
  border-radius: 5px 20px 20px 30px;
  width: fit-content;
  white-space: pre-line;
  word-wrap: break-word;
  word-break: break-all;
}

.chat-my-content {
  position: relative;
  border-radius: 20px 1px 30px 20px;
  padding: 10px;
  margin-left: 33px;
  background: #95ec69;
  color: black;
  white-space: pre-line;
  word-wrap: break-word;
  word-break: break-all;
}

.submit-btn {
  color: rgb(31, 147, 255);
}

.emoji {
  cursor: pointer;
  font-size: 1.3rem;
  margin: 0 8px;
}

.emoji-box {
  position: absolute;
  box-shadow: 0 8px 16px rgba(50, 50, 93, 0.08), 0 4px 12px rgba(0, 0, 0, 0.07);
  background: #fff;
  border-radius: 8px;
  right: 20px;
  bottom: 52px;
  height: 180px;
  width: 300px;
  overflow-y: auto;
  padding: 6px 16px;
}

.emoji-border:before {
  display: block;
  height: 0;
  width: 0;
  content: "";
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 12px solid #fff;
  bottom: 40px;
  position: absolute;
  right: 43px;
}

.unread {
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  height: 20px;
  width: 20px;
  position: absolute;
  background: #f24f2d;
  color: #fff;
}

.back-menu {
  font-size: 13px;
  border-radius: 2px;
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  color: #000;
  width: 80px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  display: none;
}

.voice {
  position: fixed;
  z-index: 1500;
  bottom: 52px;
  left: 0;
  right: 0;
  top: 80px;
  background: rgba(0, 0, 0, 0.8);
}

.close-voice {
  position: absolute;
  bottom: 60px;
  left: 30px;
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  background: #fff;
}
</style>
