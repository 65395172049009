export default {
  "[微笑]": "https://formoji.fun/img/emoji/001_微笑.webp",
  "[撇嘴]": "https://formoji.fun/img/emoji/002_撇嘴.webp",
  "[色]": "https://formoji.fun/img/emoji/003_色.webp",
  "[发呆]": "https://formoji.fun/img/emoji/004_发呆.webp",
  "[得意]": "https://formoji.fun/img/emoji/005_得意.webp",
  "[流泪]": "https://formoji.fun/img/emoji/006_流泪.webp",
  "[害羞]": "https://formoji.fun/img/emoji/007_害羞.webp",
  "[闭嘴]": "https://formoji.fun/img/emoji/008_闭嘴.webp",
  "[睡]": "https://formoji.fun/img/emoji/009_睡.webp",
  "[大哭]": "https://formoji.fun/img/emoji/010_大哭.webp",
  "[尴尬]": "https://formoji.fun/img/emoji/011_尴尬.webp",
  "[发怒]": "https://formoji.fun/img/emoji/012_发怒.webp",
  "[调皮]": "https://formoji.fun/img/emoji/013_调皮.webp",
  "[呲牙]": "https://formoji.fun/img/emoji/014_呲牙.webp",
  "[惊讶]": "https://formoji.fun/img/emoji/015_惊讶.webp",
  "[难过]": "https://formoji.fun/img/emoji/016_难过.webp",
  "[囧]": "https://formoji.fun/img/emoji/017_囧.webp",
  "[抓狂]": "https://formoji.fun/img/emoji/018_抓狂.webp",
  "[吐]": "https://formoji.fun/img/emoji/019_吐.webp",
  "[偷笑]": "https://formoji.fun/img/emoji/020_偷笑.webp",
  "[愉快]": "https://formoji.fun/img/emoji/021_愉快.webp",
  "[白眼]": "https://formoji.fun/img/emoji/022_白眼.webp",
  "[傲慢]": "https://formoji.fun/img/emoji/023_傲慢.webp",
  "[困]": "https://formoji.fun/img/emoji/024_困.webp",
  "[惊恐]": "https://formoji.fun/img/emoji/025_惊恐.webp",
  "[憨笑]": "https://formoji.fun/img/emoji/026_憨笑.webp",
  "[悠闲]": "https://formoji.fun/img/emoji/027_悠闲.webp",
  "[咒骂]": "https://formoji.fun/img/emoji/028_咒骂.webp",
  "[疑问]": "https://formoji.fun/img/emoji/029_疑问.webp",
  "[嘘]": "https://formoji.fun/img/emoji/030_嘘.webp",
  "[晕]": "https://formoji.fun/img/emoji/031_晕.webp",
  "[衰]": "https://formoji.fun/img/emoji/032_衰.webp",
  "[骷髅]": "https://formoji.fun/img/emoji/033_骷髅.webp",
  "[敲打]": "https://formoji.fun/img/emoji/034_敲打.webp",
  "[再见]": "https://formoji.fun/img/emoji/035_再见.webp",
  "[擦汗]": "https://formoji.fun/img/emoji/036_擦汗.webp",
  "[抠鼻]": "https://formoji.fun/img/emoji/037_抠鼻.webp",
  "[鼓掌]": "https://formoji.fun/img/emoji/038_鼓掌.webp",
  "[坏笑]": "https://formoji.fun/img/emoji/039_坏笑.webp",
  "[右哼]": "https://formoji.fun/img/emoji/040_右哼哼.webp",
  "[鄙视]": "https://formoji.fun/img/emoji/041_鄙视.webp",
  "[委屈]": "https://formoji.fun/img/emoji/042_委屈.webp",
  "[快哭]": "https://formoji.fun/img/emoji/043_快哭了.webp",
  "[阴险]": "https://formoji.fun/img/emoji/044_阴险.webp",
  "[亲亲]": "https://formoji.fun/img/emoji/045_亲亲.webp",
  "[可怜]": "https://formoji.fun/img/emoji/046_可怜.webp",
  "[笑脸]": "https://formoji.fun/img/emoji/047_笑脸.webp",
  "[生病]": "https://formoji.fun/img/emoji/048_生病.webp",
  "[脸红]": "https://formoji.fun/img/emoji/049_脸红.webp",
  "[破涕]": "https://formoji.fun/img/emoji/050_破涕为笑.webp",
  "[恐惧]": "https://formoji.fun/img/emoji/051_恐惧.webp",
  "[失望]": "https://formoji.fun/img/emoji/052_失望.webp",
  "[无语]": "https://formoji.fun/img/emoji/053_无语.webp",
  "[嘿哈]": "https://formoji.fun/img/emoji/054_嘿哈.webp",
  "[捂脸]": "https://formoji.fun/img/emoji/055_捂脸.webp",
  "[奸笑]": "https://formoji.fun/img/emoji/056_奸笑.webp",
  "[机智]": "https://formoji.fun/img/emoji/057_机智.webp",
  "[皱眉]": "https://formoji.fun/img/emoji/058_皱眉.webp",
  "[耶]": "https://formoji.fun/img/emoji/059_耶.webp",
  "[吃瓜]": "https://formoji.fun/img/emoji/060_吃瓜.webp",
  "[加油]": "https://formoji.fun/img/emoji/061_加油.webp",
  "[汗.]": "https://formoji.fun/img/emoji/062_汗.webp",
  "[天啊]": "https://formoji.fun/img/emoji/063_天啊.webp",
  "[Emm]": "https://formoji.fun/img/emoji/064_Emm.webp",
  "[社会]": "https://formoji.fun/img/emoji/065_社会社会.webp",
  "[旺柴]": "https://formoji.fun/img/emoji/066_旺柴.webp",
  "[好的]": "https://formoji.fun/img/emoji/067_好的.webp",
  "[打脸]": "https://formoji.fun/img/emoji/068_打脸.webp",
  "[哇]": "https://formoji.fun/img/emoji/069_哇.webp",
  "[翻白]": "https://formoji.fun/img/emoji/070_翻白眼.webp",
  "[666]": "https://formoji.fun/img/emoji/071_666.webp",
  "[让我]": "https://formoji.fun/img/emoji/072_让我看看.webp",
  "[叹气]": "https://formoji.fun/img/emoji/073_叹气.webp",
  "[苦涩]": "https://formoji.fun/img/emoji/074_苦涩.webp",
  "[裂开]": "https://formoji.fun/img/emoji/075_裂开.webp",
  "[嘴唇]": "https://formoji.fun/img/emoji/076_嘴唇.webp",
  "[爱心]": "https://formoji.fun/img/emoji/077_爱心.webp",
  "[心碎]": "https://formoji.fun/img/emoji/078_心碎.webp",
  "[拥抱]": "https://formoji.fun/img/emoji/079_拥抱.webp",
  "[强]": "https://formoji.fun/img/emoji/080_强.webp",
  "[弱]": "https://formoji.fun/img/emoji/081_弱.webp",
  "[握手]": "https://formoji.fun/img/emoji/082_握手.webp",
  "[胜利]": "https://formoji.fun/img/emoji/083_胜利.webp",
  "[抱拳]": "https://formoji.fun/img/emoji/084_抱拳.webp",
  "[勾引]": "https://formoji.fun/img/emoji/085_勾引.webp",
  "[拳头]": "https://formoji.fun/img/emoji/086_拳头.webp",
  "[OK]": "https://formoji.fun/img/emoji/087_OK.webp",
  "[合十]": "https://formoji.fun/img/emoji/088_合十.webp",
  "[啤酒]": "https://formoji.fun/img/emoji/089_啤酒.webp",
  "[咖啡]": "https://formoji.fun/img/emoji/090_咖啡.webp",
  "[蛋糕]": "https://formoji.fun/img/emoji/091_蛋糕.webp",
  "[玫瑰]": "https://formoji.fun/img/emoji/092_玫瑰.webp",
  "[凋谢]": "https://formoji.fun/img/emoji/093_凋谢.webp",
  "[菜刀]": "https://formoji.fun/img/emoji/094_菜刀.webp",
  "[炸弹]": "https://formoji.fun/img/emoji/095_炸弹.webp",
  "[便便]": "https://formoji.fun/img/emoji/096_便便.webp",
  "[月亮]": "https://formoji.fun/img/emoji/097_月亮.webp",
  "[太阳]": "https://formoji.fun/img/emoji/098_太阳.webp",
  "[庆祝]": "https://formoji.fun/img/emoji/099_庆祝.webp",
  "[礼物]": "https://formoji.fun/img/emoji/100_礼物.webp",
  "[红包]": "https://formoji.fun/img/emoji/101_红包.webp",
  "[發]": "https://formoji.fun/img/emoji/102_發.webp",
  "[福]": "https://formoji.fun/img/emoji/103_福.webp",
  "[烟花]": "https://formoji.fun/img/emoji/104_烟花.webp",
  "[爆竹]": "https://formoji.fun/img/emoji/105_爆竹.webp",
  "[猪头]": "https://formoji.fun/img/emoji/106_猪头.webp",
  "[跳跳]": "https://formoji.fun/img/emoji/107_跳跳.webp",
  "[发抖]": "https://formoji.fun/img/emoji/108_发抖.webp",
  "[转圈]": "https://formoji.fun/img/emoji/109_转圈.webp"
};
