<template>
  <v-app id="app">
    <!-- 导航栏 -->
    <TopNavBar></TopNavBar>
    <!-- 侧边导航栏 -->
    <SideNavBar></SideNavBar>
    <!-- 内容 -->
    <v-content>
      <router-view :key="$route.fullPath" />
    </v-content>
    <!-- 页脚 -->
    <Footer></Footer>
    <!-- 返回顶部 -->
    <BackTop></BackTop>
    <!-- 搜索模态框 -->
    <!-- <searchModel></searchModel> -->
    <!-- 登录模态框 -->
    <LoginModel></LoginModel>
    <!-- 注册模态框 -->
    <RegisterModel></RegisterModel>
    <!-- 忘记密码模态框 -->
    <ForgetModel></ForgetModel>
    <!-- 绑定邮箱模态框 -->
    <EmailModel></EmailModel>
    <PayModel></PayModel>
    <!-- 音乐播放器 -->
    <!-- <Player v-if="blogInfo.websiteConfig.isMusicPlayer == 1 && !isMobile" /> -->
    <!-- 聊天室 -->
    <ChatRoom v-if="blogInfo.websiteConfig.isChatRoom == 1"></ChatRoom>
  </v-app>
</template>

<script>
import TopNavBar from "./components/layout/TopNavBar";
import SideNavBar from "./components/layout/SideNavBar";
import Footer from "./components/layout/Footer";
import BackTop from "./components/BackTop";
// import searchModel from "./components/model/SearchModel";
import LoginModel from "./components/model/LoginModel";
import RegisterModel from "./components/model/RegisterModel";
import ForgetModel from "./components/model/ForgetModel";
import EmailModel from "./components/model/EmailModel";
import PayModel from "./components/model/PayModel";
// import Player from "./components/zw-player/player.vue";
import ChatRoom from "./components/ChatRoom";

export default {
  created() {
    // fresh user
    this.freshUserInfo();
    // 获取博客信息
    this.getBlogInfo();

    this.$sensors.registerPage({
      current_url: location.href,
      referrer: document.referrer,
      testName: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
      userinfo: localStorage.getItem("userinfo")
    });
    this.$sensors.setProfile({ "user_uuid": "abcd1234", "button_name": "Submit", "event_id": 1, "event_time": "2022-01-01 11:30:00", "count": 5 });


    // 上传访客信息
    this.axios
      .post("/api/report")
      .then(response => {
        let data = response.data;
        if (data.data == null) {
          // 清空登录缓存 todo
          this.$store.state.avatar = "";
          localStorage.removeItem("userinfo");
        }
      })
      .catch(error => {
        // 请求发生错误时的处理逻辑
        console.error(error);
      });
      
  },
  components: {
    TopNavBar,
    // Player,
    SideNavBar,
    Footer,
    BackTop,
    // searchModel,
    LoginModel,
    RegisterModel,
    ForgetModel,
    EmailModel,
    PayModel,
    ChatRoom
  },
  methods: {
    getBlogInfo() {
      this.axios.get("/api/").then(({ data }) => {
        this.$store.commit("checkBlogInfo", data.data);
      });
    },
    freshUserInfo() {
      let that = this;
      // 登录信息
      let loginInfo = localStorage.getItem("userinfo");
      if (loginInfo != undefined) {
        that.$store.commit("login", JSON.parse(loginInfo));
        that.$store.commit("closeModel");
      }
    }
  },
  computed: {
    blogInfo() {
      return this.$store.state.blogInfo;
    },
    isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    }
  }
};

</script>
