<template>
  <v-dialog v-model="loginFlag" :fullscreen="isMobile" max-width="460" style="z-index: 9999;">
    <v-card class="login-container" style="border-radius:4px">
      <v-icon class="float-right" @click="loginFlag = false">
        mdi-close
      </v-icon>
      <div class="login-wrapper" style="z-index: 9999;" v-if="!showQr">
        <!-- 用户名 -->
        <v-text-field
          v-model="username"
          label="邮箱号"
          placeholder="请输入您的邮箱号"
          clearable
          @keyup.enter="login"
        />
        <!-- 密码 -->
        <v-text-field
          v-model="password"
          class="mt-7"
          label="密码"
          placeholder="请输入您的密码"
          @keyup.enter="login"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
        />
        <!-- 按钮 -->
        <v-btn
          class="mt-7"
          block
          color="blue"
          style="color:#fff"
          @click="login"
        >
          登录
        </v-btn>
        <!-- 注册和找回密码 -->
        <div class="mt-10 login-tip">
          还没有账号？<a @click="openRegister">前往注册</a>
          <span @click="openForget" class="float-right">忘记密码?</span>
        </div>
        <div v-if="socialLoginList.length > 0">
          <div class="social-login-title">社交账号登录</div>
          <div class="social-login-wrapper">
            <!-- 微博登录 -->
            <a
              v-if="true"
              class="mr-3 iconfont icon-weixin"
              style="color:green; font-size: 2rem"
              @click="wechatLogin"
            />
            <!-- qq登录 -->
            <a
              v-if="showLogin('qq')"
              class="iconfont icon-QQ"
              style="color:#00AAEE; font-size: 2rem"
              @click="qqLogin"
            />
          </div>
        </div>
      </div>
      <div v-if="showQr">
        <img style="width:200px;margin: auto;display: block" :src="qr" :size="200" >
        <!-- <el-image style="width:200px;margin: auto;display: block" :src="qr" :size="200"></el-image> -->
        <div style="width: 126px;margin: 30px auto;font-size: 14px">
          请使用微信扫码登录
        </div>
        <div class="social-login-wrapper">
          <div class="social-login-title">账号登录</div>
          <div style="font-size: 18px; cursor:pointer;">
            <a @click="openRegister" style="padding-right: 22px;">立即注册</a>
            <a @click="change2Login">帐号登录</a>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: function() {
    return {
      username: "",
      password: "",
      show: false,
      loginLoading: false,
      rememberMe: false,
      platform: '',
      showQr: false,
      qr: 'https://oss.jylt.cc/img/img_loading.gif',
      tempUserId: '',
      secret: '8883549e',
      timer: null
    };
  },
  computed: {
    loginFlag: {
      set(value) {
        this.$store.state.loginFlag = value;
      },
      get() {
        return this.$store.state.loginFlag;
      }
    },
    isMobile() {
      const clientWidth = document.documentElement.clientWidth;
      if (clientWidth > 960) {
        return false;
      }
      return true;
    },
    socialLoginList() {
      return this.$store.state.blogInfo.websiteConfig.socialLoginList;
    },
    showLogin() {
      return function(type) {
        return this.socialLoginList.indexOf(type) != -1;
      };
    }
  },
  methods: {
    openRegister() {
      this.$store.state.loginFlag = false;
      this.$store.state.registerFlag = true;
    },
    openForget() {
      this.$store.state.loginFlag = false;
      this.$store.state.forgetFlag = true;
    },
    login() {
      var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!reg.test(this.username)) {
        this.$toast({ type: "error", message: "邮箱格式不正确" });
        return false;
      }
      if (this.password.trim().length == 0) {
        this.$toast({ type: "error", message: "密码不能为空" });
        return false;
      }
      const that = this;
      //// eslint-disable-next-line no-undef
      //var captcha = new TencentCaptcha(this.config.TENCENT_CAPTCHA, function(
      //  res
      //) {
      //  if (res.ret === 0) {
      //    //发送登录请求
      //    let param = new URLSearchParams();
      //    param.append("username", that.username);
      //    param.append("password", that.password);
      //    that.axios.post("/api/login", param).then(({ data }) => {
      //      if (data.flag) {
      //        that.username = "";
      //        that.password = "";
      //        that.$store.commit("login", data.data);
      //        that.$store.commit("closeModel");
      //        that.$toast({ type: "success", message: "登录成功" });
      //      } else {
      //        that.$toast({ type: "error", message: data.message });
      //      }
      //    });
      //  }
      //});
      //// 显示验证码
      //captcha.show();
      //发送登录请求
          let param = new URLSearchParams();
          param.append("username", that.username);
          param.append("password", that.password);
          that.axios.post("/api/login", param).then(({ data }) => {
            this.loginSuccess(data);
           
          });
    },
    qqLogin() {
      //保留当前路径
      this.$store.commit("saveLoginUrl", this.$route.path);
      if (
        navigator.userAgent.match(
          /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
        )
      ) {
        // eslint-disable-next-line no-undef
        QC.Login.showPopup({
          appId: this.config.QQ_APP_ID,
          redirectURI: this.config.QQ_REDIRECT_URI
        });
      } else {
      window.open("https://graph.qq.com/oauth2.0/show?which=Login&display=pc&client_id=" +
            +this.config.QQ_APP_ID +
            "&response_type=token&scope=all&redirect_uri=" +
            this.config.QQ_REDIRECT_URI,
            "_self"
            );
      }
    },
    weiboLogin() {
      //保留当前路径
      this.$store.commit("saveLoginUrl", this.$route.path);
      window.open(
        "https://api.weibo.com/oauth2/authorize?client_id=" +
          this.config.WEIBO_APP_ID +
          "&response_type=code&redirect_uri=" +
          this.config.WEIBO_REDIRECT_URI,
        "_self"
      );
    },
    wechatLogin() {
      console.log("wechat ");
      //保留当前路径
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.showQr = true;
      this.getQrInfo();
    },
    getQrInfo() {
      let that  = this;
      that.axios.get("/api/other/login/wxQr?secret=" + this.secret).then(res => {
        if (res.data.flag) {
          this.tempUserId = res.data.data.tempUserId;
          this.qr = res.data.data.qrUrl;
          this.timer = setInterval(() => {
            //java后台。
            this.axios.get("/api/other/login/wx/is_login?tempUserId=" + this.tempUserId).then((res) => {
              if (res.data.flag) {
                this.loginSuccess(res.data);
              }
            });
            //退出微信登录，应该终止 不是微信的，都要终止 
            if (!this.showQr || !this.loginFlag) {
              clearInterval(this.timer);
              this.showQr = false;
            }
          }, 1000);
        }
      })
    },
    getAuthCode() {
      let that  = this;
      let code = localStorage.getItem("authCode");
      localStorage.removeItem("authCode");
      if (!code) {
        return;
      }
      let formData = new FormData();
      formData.append("code", code);
      formData.append("platform", this.platform);
      that.axios.post("/api/other/login/login", formData).then(res => {
        if (res.data.code === 200) {
          that.loginSuccess();
        } else {
          that.$message.error("登录失败");
        }
      }).catch(e => {
        console.error(e);
        that.$message.error("登录失败");
      })
    },
    change2Login() {
      this.showQr = false;
    },
    loginSuccess(data) {
      let that = this;
      if (this.timer) {
        clearInterval(this.timer);
      }
      //login成功，要做些工作的。存储用户数据。
      if (data.flag) {
        that.username = "";
        that.password = "";
        that.$store.commit("login", data.data);
        localStorage.setItem("userinfo", JSON.stringify(data.data));
        that.$store.commit("closeModel");
        that.$toast({ type: "success", message: "登录成功" });
        this.$sensors.setProfile({userinfo: JSON.stringify(data.data), email:"hshe@11.xom"});
        this.$sensors.registerPage({
          current_url: location.href,
          referrer: document.referrer,
          userinfo: JSON.stringify(data.data)
        });
      } else {
        that.$toast({ type: "error", message: data.message });
      }
      that.change2Login();
    },
    cancel() {
      this.$router.push("/")
    },
  }
};
</script>

<style scoped>
.social-login-title {
  margin-top: 1.5rem;
  color: #b5b5b5;
  font-size: 0.75rem;
  text-align: center;
}
.social-login-title::before {
  content: "";
  display: inline-block;
  background-color: #d8d8d8;
  width: 60px;
  height: 1px;
  margin: 0 12px;
  vertical-align: middle;
}
.social-login-title::after {
  content: "";
  display: inline-block;
  background-color: #d8d8d8;
  width: 60px;
  height: 1px;
  margin: 0 12px;
  vertical-align: middle;
}
.social-login-wrapper {
  margin-top: 1rem;
  font-size: 2rem;
  text-align: center;
}
.social-login-wrapper a {
  text-decoration: none;
}
</style>
