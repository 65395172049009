<template>
  <v-dialog v-model="payFlag" :fullscreen="isMobile" max-width="460">
    <v-card class="login-container" style="border-radius:4px">
      <v-icon class="float-right" @click="payFlag = false">
        mdi-close
      </v-icon>
      <div class="login-wrapper">
        <v-text-field
          v-model="cash"
          label="金额"
          placeholder="填写金额"
          clearable
          @keyup.enter="saveUsercash"
        />
        <v-btn
          class="mt-7"
          block
          color="blue"
          style="color:#fff"
          @click="saveUsercash"
        >
          创建订单
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: function() {
    return {
      cash: this.$store.state.cash,
      code: "",
      flag: true,
      codeMsg: "发送",
      time: 60,
      show: false
    };
  },
  methods: {
    countDown() {
      this.flag = true;
      this.timer = setInterval(() => {
        this.time--;
        this.codeMsg = this.time + "s";
        if (this.time <= 0) {
          clearInterval(this.timer);
          this.codeMsg = "发送";
          this.time = 60;
          this.flag = false;
        }
      }, 1000);
    },
    saveUsercash() {
      window.open("/api/alipay/pay?&subject=充值" + this.cash + "&totalAmount=" + this.cash, '_blank');
    }
  },
  computed: {
    payFlag: {
      set(value) {
        this.$store.state.payFlag = value;
      },
      get() {
        return this.$store.state.payFlag;
      }
    },
    isMobile() {
      const clientWidth = document.documentElement.clientWidth;
      if (clientWidth > 960) {
        return false;
      }
      return true;
    }
  },
};
</script>

<style scoped>
@media (min-width: 760px) {
  .login-container {
    padding: 1rem;
    border-radius: 4px;
    height: 400px;
  }
}
</style>
