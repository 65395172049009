import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import animated from "animate.css";
import "./assets/css/index.css";
import "./assets/css/iconfont.css";
// import "./assets/css/markdown.css";
import "./assets/css/vue-social-share/client.css";
import config from "./assets/js/config";
import Share from "vue-social-share";
import dayjs from "dayjs";
import { vueBaberrage } from "vue-baberrage";
import axios from "axios";
import VueAxios from "vue-axios";
import InfiniteLoading from "vue-infinite-loading";
import "highlight.js/styles/atom-one-dark.css";
import VueImageSwipe from "vue-image-swipe";

import Toast from "./components/toast/index";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import "./assets/icon/iconfont.css";
import VueLazyload from 'vue-lazyload'

//通知
import notifyMessage from './components/notifyMessageJs.js';


Vue.use(notifyMessage.register);

// import VueAnalytics from 'vue-analytics';
Vue.prototype.config = config;
Vue.config.productionTip = false;
Vue.use(animated);
Vue.use(Share);
Vue.use(vueBaberrage);
Vue.use(InfiniteLoading);
Vue.use(VueAxios, axios);
Vue.use(VueImageSwipe);
Vue.use(Toast);
Vue.use(VueLazyload);

Vue.filter("date", function (value) {
  return dayjs(value).format("YYYY-MM-DD");
});

Vue.filter("year", function (value) {
  return dayjs(value).format("YYYY");
});

Vue.filter("hour", function (value) {
  return dayjs(value).format("HH:mm:ss");
});

Vue.filter("time", function (value) {
  return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
});

Vue.filter("num", function (value) {
  if (value >= 1000) {
    return (value / 1000).toFixed(1) + "k";
  }
  return value;
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});


//vue 项目在路由切换的时候调用
var sensors = require('sa-sdk-javascript');
sensors.init({
  server_url: 'http://qy.formoji.fun:28087/sa.gif?token=abc&project=winhone',
  is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
  use_client_time: true,
  send_type: 'image',
  show_log: true,
  heatmap: {
    //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
    clickmap: 'default',
    //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
    scroll_notice_map: 'default'
  }
});
sensors.quick('autoTrack'); //用于采集 $pageview 事件。

// 事件注册到vue组件上
Vue.prototype.$sensors = sensors;



router.afterEach(() => {
  window.scrollTo({
    top: 0,
    behavior: "instant"
  });
  NProgress.done();
});

axios.interceptors.response.use(
  function (response) {
    switch (response.data.code) {
      case 50000:
        Vue.prototype.$toast({ type: "error", message: "系统异常" });
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);



// Vue.use(VueAnalytics, {
//   id: 'G-44GCT019FF',       //跟踪ID
//   debug: {
//     enabled: true,          //开启Debug调试
//     trace: false,
//     sendHitTask: true
//   }
// })


//第一个页面，都进行统计
// router.beforeEach((to, from, next) => {
//   if (window._hmt) {
//       if (to.path) {
//         window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
//       }
//   }
//   next();
// });

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
