import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: resolve => require(["../views/home/Home.vue"], resolve)
  },
  {
    path: "/articles/:articleId",
    component: resolve => require(["../views/article/Article.vue"], resolve)
  },
  {
    path: "/archives",
    component: resolve => require(["../views/archive/Archive.vue"], resolve),
    meta: {
      title: "归档"
    }
  },
  {
    path: "/albums",
    component: resolve => require(["../views/album/Album.vue"], resolve),
    meta: {
      title: "相册"
    }
  },
  {
    path: "/talks",
    component: resolve => require(["../views/talk/Talk.vue"], resolve),
    meta: {
      title: "说说"
    }
  },
  {
    path: "/talks/:talkId",
    component: resolve => require(["../views/talk/TalkInfo.vue"], resolve),
    meta: {
      title: "说说"
    }
  },
  {
    path: "/albums/:albumId",
    component: resolve => require(["../views/album/Photo.vue"], resolve)
  },
  {
    path: "/tags",
    component: resolve => require(["../views/tag/Tag.vue"], resolve),
    meta: {
      title: "标签"
    }
  },
  {
    path: "/categories",
    component: resolve => require(["../views/category/Category.vue"], resolve),
    meta: {
      title: "分类"
    }
  },
  {
    path: "/categories/:categoryId",
    component: resolve => require(["../views/article/ArticleList.vue"], resolve)
  },
  {
    path: "/tags/:tagId",
    component: resolve => require(["../views/article/ArticleList.vue"], resolve)
  },
  {
    path: "/links",
    component: resolve => require(["../views/link/Link.vue"], resolve),
    meta: {
      title: "友链列表"
    }
  },
  {
    path: "/about",
    component: resolve => require(["../views/about/About.vue"], resolve),
    meta: {
      title: "关于我"
    }
  },
  {
    path: "/message",
    component: resolve => require(["../views/message/Message.vue"], resolve),
    meta: {
      title: "留言板"
    }
  },
  {
    path: "/user",
    component: resolve => require(["../views/user/User.vue"], resolve),
    meta: {
      title: "个人中心"
    }
  },
  {
    path: "/users/:Id",
    component: resolve => require(["../views/user/Users.vue"], resolve),
    meta: {
      title: "用户详情"
    }
  },
  {
    path: "/oauth/login/qq",
    component: resolve => require(["../components/OauthLogin.vue"], resolve)
  },
  {
    path: "/oauth/login/weibo",
    component: resolve => require(["../components/OauthLogin.vue"], resolve)
  },
  {
    path: "/oauth/login/wechat",
    component: resolve => require(["../components/OauthLogin.vue"], resolve)
  },
  {
    path: "/battle",
    component: resolve => require(["../views/battle/Battle.vue"], resolve),
    meta: {
      title: "战斗信息"
    }
  },
  {
    path: "/battles/:Id",
    component: resolve => require(["../views/battle/BattleInfo.vue"], resolve)
  },
  {
    path: "/post",
    component: resolve => require(["../views/article/PostArticle.vue"], resolve)
  },
  {
    path: "/posts/:Id",
    component: resolve => require(["../views/article/PostArticle.vue"], resolve)
  },
  {
    path: "/post-list",
    component: resolve => require(["../views/article/PostArticleList.vue"], resolve)
  },
  {
    path: "/share",
    component: resolve => require(["../views/share/PostShare.vue"], resolve)
  },
  {
    path: "/shares/:Id",
    component: resolve => require(["../views/share/PostShare.vue"], resolve)
  },
  {
    path: "/share-list",
    component: resolve => require(["../views/share/PostShareList.vue"], resolve)
  },
  {
    path: "/order",
    component: resolve => require(["../views/order/Order.vue"], resolve)
  },
  {
    path: "/sd",
    component: resolve => require(["../views/sd/sd.vue"], resolve)
  },
  {
    path: "/kcode",
    component: resolve => require(["../views/kcode/kcode.vue"], resolve)
  },
  {
    path: "/draw",
    component: resolve => require(["../views/draw/draw.vue"], resolve)
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
